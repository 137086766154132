/* istanbul ignore file */
/* eslint-disable */
import React from "react";
import { Table } from "react-bootstrap";
import { LabeledRadioInput } from "adviesbox-shared";
import "./scenario-table.scss";
import classNames from "classnames";
import { NbiResult, InkomensanalyseResultaat } from "../../../.generated/rapportage/rapportagetypes";
import { TableNettoBesteedbaarInkomen } from "./nbi";
import { bedragFormatNull, monthDivision, getConclusieArcering, getMaandlastKeuzeTekst } from "./infra/scenario-utils";
import { Formik } from "formik";
import { Direction } from "../../../shared/types";
import { ControleMaandlastOptions } from "../../../.generated/forms/formstypes";
import { CardLoadingSpinner } from "./card-loading-spinner";
import { ScenarioBaseProps } from "./scenario-drawer";
import { getNaam } from "../../../shared/utils/helpers";
import { MaximaleHypotheekTable } from "./tables/maximale-hypotheek";

export type PensioenDataProps = {
  nbiWeergeven: boolean;
  titel: string;
  conclusie: string;
  inkomensanalyseResultaten: InkomensanalyseResultaat[];
  totaalHuidigBrutoJaarinkomenBedrag: number | null;
  nbi: NbiResult[];
  inkomensData?: {
    werkgeversPensioenAanvrager: number | null;
    werkgeversPensioenPartner: number | null;
    restinkomenAanvrager: number | null;
    restinkomenPartner: number | null;
    aowInkomenAanvrager: number | null;
    aowInkomenPartner: number | null;
    totaalInkomen: number | null;
    inkomensverlies: number | null;
  };
  financieleGegevens?: {
    huidigInkomen70Procent: number | null;
    benodigdInkomenHypotheek: number | null;
    toegestaneLast: number | null;
    tekortWerkelijkeBrutolast: number | null;
    werkelijkeBrutoLast: number | null;
    tekortWerkelijk: number | null;
    maximaleHypotheek: number | null;
    hypotheekSaldo: number | null;
    overschrijding: number | null;
    tekortOpInkomen: number | null;
  };
  jaarlijkseAnalyse?: {
    gewenstInkomen: number | null;
    gewenstInkomenPercentage: number | null;
    maandelijksTekort: number | null;
  };
  voetnoten: string[];
  benodigdKapitaal: number | null;
  benodigdKapitaalOmschrijving: string;
};

export const InkomensanalyseTabelPensioen: React.FC<ScenarioBaseProps & PensioenDataProps> = ({
  titel,
  conclusie,
  inkomensData,
  financieleGegevens,
  voetnoten,
  nbi,
  nbiWeergeven,
  naamAanvrager,
  naamPartner,
  weergevenOptions,
  onKlantIdChange,
  selectedKlantId,
  benodigdKapitaal,
  benodigdKapitaalOmschrijving,
  benodigdKapitaalWeergeven,
  controleWerkelijkeLastenWeergeven,
  brutoInkomensWensenWeergeven,
  brutoInkomensWensPercentage,
  controleMaandlast,
  controleMaandlastKeuze,
  maximaleHypotheekWeergeven,
  jaarlijkseAnalyse,
  loading,
  inkomensanalyseResultaten,
  totaalHuidigBrutoJaarinkomenBedrag
}) => {
  if (!inkomensanalyseResultaten?.[0]) return <></>;

  const columnStyleLabel = {
    width: "70%"
  };
  const columnStyle = {
    width: "30%",
    textAlign: "right" as const
  };

  return (
    <div className="container-fluid mt-2 px-2">
      {loading && <CardLoadingSpinner />}
      {weergevenOptions.length > 0 && (
        <Formik initialValues={{ scenarioKlantId: selectedKlantId }} enableReinitialize onSubmit={() => {}}>
          {({ setFieldValue }) => (
            <div className={"col-md-6 pb-3"}>
              <LabeledRadioInput
                name={"scenarioKlantId"}
                caption={"Weergeven scenario van"}
                options={weergevenOptions}
                onChange={event => {
                  onKlantIdChange(event.target.value);
                  setFieldValue("scenarioKlantId", event.target.value);
                }}
                layout={Direction.Horizontal}
              />
            </div>
          )}
        </Formik>
      )}
      <Table striped bordered hover responsive className="scenario__table mb-4">
        <colgroup>
          <col style={columnStyleLabel} />
          <col style={columnStyle} />
        </colgroup>
        <thead>
          <tr>
            <th colSpan={4}>Uw inkomensanalyse</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4}>Uw inkomen bij pensioen van {getNaam(naamAanvrager, "Aanvrager 1")}</td>
          </tr>
          <tr>
            <td>AOW {getNaam(naamAanvrager, "Aanvrager 1")}</td>
            <td>{bedragFormatNull(inkomensData?.aowInkomenAanvrager, monthDivision)}</td>
          </tr>

          <tr>
            <td>Werkgeverspensioen {getNaam(naamAanvrager, "Aanvrager 1")}</td>
            <td>{bedragFormatNull(inkomensData?.werkgeversPensioenAanvrager, monthDivision)}</td>
          </tr>

          {naamPartner && (
            <>
              <tr>
                <td>AOW {getNaam(naamPartner, "Aanvrager 2")}</td>
                <td>{bedragFormatNull(inkomensData?.aowInkomenPartner, monthDivision)}</td>
              </tr>
              <tr>
                <td>Inkomen van {getNaam(naamPartner, "Aanvrager 2")}</td>
                <td>{bedragFormatNull(inkomensData?.restinkomenPartner, monthDivision)}</td>
              </tr>
              <tr>
                <td>Werkgeverspensioen {getNaam(naamPartner, "Aanvrager 2")}</td>
                <td>{bedragFormatNull(inkomensData?.werkgeversPensioenPartner, monthDivision)}</td>
              </tr>
            </>
          )}
          <tr>
            <td>
              <strong>Inkomen totaal</strong>
            </td>
            <td>{bedragFormatNull(inkomensData?.totaalInkomen, monthDivision)}</td>
          </tr>
          <tr className="table-warning">
            <td>Inkomensverlies</td>
            <td>{bedragFormatNull(inkomensData?.inkomensverlies)}</td>
          </tr>
        </tbody>
      </Table>
      <Table striped bordered hover responsive className="scenario__table mb-4">
        <colgroup>
          <col style={columnStyleLabel} />
          <col style={columnStyle} />
        </colgroup>
        <tbody>
          {brutoInkomensWensenWeergeven && (
            <>
              <tr>
                <td>Gewenst inkomen van minimaal {brutoInkomensWensPercentage}% van huidig inkomen</td>
                <td>{bedragFormatNull((totaalHuidigBrutoJaarinkomenBedrag||0) * ((brutoInkomensWensPercentage||0)/100), monthDivision)}</td>
              </tr>
              <tr className={"table-danger font-weight-bold"}>
                <td>Maandelijks tekort aan gewenste inkomsten</td>
                <td>{bedragFormatNull(Math.min(jaarlijkseAnalyse?.maandelijksTekort || 0, 0))}</td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
      <Table striped bordered hover responsive className="scenario__table mb-4">
        <colgroup>
          <col style={columnStyleLabel} />
          <col style={columnStyle} />
        </colgroup>
        <tbody>
          {controleWerkelijkeLastenWeergeven && (
            <>
              <tr>
                <td>Toegestane last o.b.v. inkomen**</td>
                <td>{bedragFormatNull(financieleGegevens?.toegestaneLast)}</td>
              </tr>
              <tr>
                <td>Werkelijke bruto last van hypotheek en verpande producten</td>
                <td>{bedragFormatNull(financieleGegevens?.werkelijkeBrutoLast)}</td>
              </tr>
              <tr className="table-danger">
                <td>
                  <strong>Tekort o.b.v. werkelijke bruto last</strong>
                </td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].tekortToegestaneLastBedrag)}</td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
      {controleMaandlast && controleMaandlastKeuze === ControleMaandlastOptions.BenodigdInkomen && (
        <Table striped bordered hover responsive className="scenario__table mb-4">
          <colgroup>
            <col style={columnStyleLabel} />
            <col style={columnStyle} />
          </colgroup>
          <tbody>
            <>
              <tr>
                <td>Minimaal benodigd inkomen voor uw hypotheek*</td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].benodigdInkomenBedrag, monthDivision)}</td>
                </tr>
              <tr className={"table-danger font-weight-bold"}>
                <td>Maandelijks tekort aan inkomsten voor uw hypotheek</td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].tekortBenodigInkomenBedrag, monthDivision)}</td>
                </tr>
            </>
          </tbody>
        </Table>
      )}

      {maximaleHypotheekWeergeven && <MaximaleHypotheekTable {...inkomensanalyseResultaten?.[0]} />}

      {controleMaandlast && (
        <Table striped bordered hover responsive className="scenario__table mb-4">
          <colgroup>
            <col style={columnStyleLabel} />
            <col style={columnStyle} />
          </colgroup>
          <tbody>
            <tr>
              <td>
                De maandlast die overblijft voor deze situatie t.o.v. de{" "}
                {getMaandlastKeuzeTekst(controleMaandlastKeuze)} ?
              </td>
              <td className={classNames("text-right", getConclusieArcering(conclusie))}>{conclusie}</td>
            </tr>
          </tbody>
        </Table>
      )}

      <p className="mt-3 text-right px-2">
        {voetnoten?.map((voetnoot, index) => (
          <React.Fragment key={index}>
            <span>{voetnoot}</span>
            <br />
          </React.Fragment>
        ))}
      </p>

      {nbiWeergeven && <TableNettoBesteedbaarInkomen nbi={nbi} />}
      {benodigdKapitaalWeergeven && (
        <Table striped bordered hover responsive className="scenario__table mb-4">
          <colgroup>
            <col style={{ width: "40%" }} />
            <col style={{ width: "60%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>Uw risico o.b.v. uw gewenste inkomen</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{benodigdKapitaalOmschrijving}</td>
              <td>{bedragFormatNull(benodigdKapitaal)}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </div>
  );
};
