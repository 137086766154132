import React from 'react';
import { Table } from 'react-bootstrap';
import { bedragFormatNull } from '../infra/scenario-utils';
import { InkomensanalyseResultaat } from '../../../../.generated/rapportage/rapportagetypes';

export const ControleWerkelijkeLastenTable: React.FC<{
   
  toegestaneLastBedrag: number | null;
  werkelijkeLastBedrag: number | null;
  tekortToegestaneLastBedrag: number | null;
    
  }> = ({ toegestaneLastBedrag, werkelijkeLastBedrag,tekortToegestaneLastBedrag} : Partial<InkomensanalyseResultaat>) => (
    <Table striped bordered hover responsive className="scenario__table mb-4">
      <colgroup>
        <col style={{ width: "70%" }} />
        <col style={{ width: "30%", textAlign: "right" }} />
      </colgroup>
      <tbody>
        <tr>
          <td>Toegestane last o.b.v. inkomen**</td>
          <td>{bedragFormatNull(toegestaneLastBedrag)}</td>
        </tr>
        <tr>
          <td>Werkelijke bruto last van hypotheek en verpande producten</td>
          <td>{bedragFormatNull(werkelijkeLastBedrag)}</td>
        </tr>
        <tr className={"table-danger font-weight-bold"}>
        <td>
            Tekort o.b.v. werkelijke bruto last
          </td>
          <td>{bedragFormatNull(tekortToegestaneLastBedrag)}</td>
        </tr>
      </tbody>
    </Table>
  );