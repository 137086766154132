import React from "react";
import { Table } from "react-bootstrap";
import { bedragFormatNull } from "../infra/scenario-utils";
import { InkomensanalyseResultaat } from "../../../../.generated/rapportage/rapportagetypes";

export const MaximaleHypotheekTable: React.FC<{
  maximaleHypotheekBedrag: number | null;
  hypotheeksaldoBedrag: number | null;
  tekortMaximaleHypotheekBedrag: number | null;
}> = ({
  maximaleHypotheekBedrag,
  hypotheeksaldoBedrag,
  tekortMaximaleHypotheekBedrag
}: Partial<InkomensanalyseResultaat>) => (
  <Table striped bordered hover responsive className="scenario__table mb-4">
    <colgroup>
      <col style={{ width: "70%" }} />
      <col style={{ width: "30%", textAlign: "right" }} />
    </colgroup>
    <tbody>
      <tr>
        <td>Maximale hypotheek**</td>
        <td>{bedragFormatNull(maximaleHypotheekBedrag)}</td>
      </tr>
      <tr>
        <td>Hypotheeksaldo</td>
        <td>{bedragFormatNull(hypotheeksaldoBedrag)}</td>
      </tr>
      <tr className={"table-danger font-weight-bold"}>
        <td>Overschrijding van uw maximale hypotheek</td>
        <td>{bedragFormatNull(Math.abs(Math.min((maximaleHypotheekBedrag || 0) - (hypotheeksaldoBedrag || 0), 0)))}</td>
      </tr>
    </tbody>
  </Table>
);
