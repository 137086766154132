/* istanbul ignore file */
/* eslint-disable */
import { MotiveringCompleetOutput } from "../../../../.generated/rapportage/rapportagetypes";
import { InkomensanalyseProps } from "../werkloosheid-drawer";
import { OverlijdenProps } from "../overlijden-drawer";
import { ArbeidsongeschiktheidProps } from "../arbeidsongeschiktheid-drawer";
import { PensioenDataProps } from "../pensioen-drawer";
import { optellen } from "adviesbox-shared";

export function mapMotiveringWWToInkomensanalyseProps(motiveringDl: MotiveringCompleetOutput): InkomensanalyseProps {
  const resultaat = motiveringDl.inkomensanalyseResultaat;
  const aanvragerInkomstenPeriodeEerst = resultaat?.aanvragerResultaten?.[0];
  const aanvragerInkomstenPeriodeTweede = resultaat?.aanvragerResultaten?.[1];
  const aanvragerInkomstenPeriodeDerde = resultaat?.aanvragerResultaten?.[2];
  const partnerInkomstenPeriodeEerste = resultaat?.partnerResultaten?.[0];
  const partnerInkomstenPeriodeTweede = resultaat?.partnerResultaten?.[1];
  const partnerInkomstenPeriodeDerde = resultaat?.partnerResultaten?.[2];

  const normGegevens = motiveringDl.normGegevens;

  if (!resultaat || !aanvragerInkomstenPeriodeEerst || !normGegevens) {
    return {} as InkomensanalyseProps;
  }

  return {
    titel: "Inkomensanalyse bij werkloosheid",
    totaalHuidigBrutoJaarinkomenBedrag: resultaat.totaalHuidigBrutoJaarinkomenBedrag,
    conclusie: aanvragerInkomstenPeriodeEerst.conclusie || "",
    inkomensanalyseResultaten: motiveringDl.motiveringInkomensanalyse?.resultaten || [],
    nbi: motiveringDl.nettoBesteedbaarInkomen || [],
    nbiWeergeven: false,
    inkomensData: {
      naamPersoon: motiveringDl.klantNaamAanvrager || "Aanvrager",
      eersteUitkering: aanvragerInkomstenPeriodeEerst.wwUitkeringBedrag,
      tweedeUitkering: aanvragerInkomstenPeriodeTweede?.wwUitkeringBedrag || 0,
      restinkomen: aanvragerInkomstenPeriodeEerst?.restInkomenBedrag,
      aantalmaandenWwUitkering: aanvragerInkomstenPeriodeEerst?.aantalMaandenWwUitkering || 0,
      totaalEerste: resultaat.totaalBrutoInkomenBedrag,
      totaalTweede: resultaat.totaalBrutoInkomenBedrag,
      totaalNa: resultaat.totaalBrutoInkomenBedrag,
      tekortEerstePeriode: resultaat.totaalHuidigBrutoJaarinkomenBedrag / 12 - resultaat.totaalBrutoInkomenBedrag / 12,
      tekortTweedePeriode:
        resultaat.totaalHuidigBrutoJaarinkomenBedrag / 12 -
        ((aanvragerInkomstenPeriodeTweede?.restInkomenBedrag || 0) +
          (aanvragerInkomstenPeriodeTweede?.wwUitkeringBedrag || 0) +
          (partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0)) /
          12,
      tekortDerdePeriode:
        resultaat.totaalHuidigBrutoJaarinkomenBedrag / 12 -
        ((aanvragerInkomstenPeriodeDerde?.restInkomenBedrag || 0) +
          (aanvragerInkomstenPeriodeDerde?.wwUitkeringBedrag || 0) +
          (partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0)) /
          12,
      totaalInkomen:
        (aanvragerInkomstenPeriodeEerst?.restInkomenBedrag || 0) +
        (aanvragerInkomstenPeriodeEerst?.wwUitkeringBedrag || 0) +
        (partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0),
      totaalInkomenTweede:
        (aanvragerInkomstenPeriodeTweede?.restInkomenBedrag || 0) +
        (aanvragerInkomstenPeriodeTweede?.wwUitkeringBedrag || 0) +
        (partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0),
      totaalInkomenDerde:
        (aanvragerInkomstenPeriodeDerde?.restInkomenBedrag || 0) +
        (partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0),
      woonlastenEerstePeriode: aanvragerInkomstenPeriodeEerst.woonlastenverzekeringBedrag || 0,
      woonlastenTweedePeriode: aanvragerInkomstenPeriodeTweede?.woonlastenverzekeringBedrag || 0,
      woonlastenDerdePeriode: aanvragerInkomstenPeriodeDerde?.woonlastenverzekeringBedrag || 0,
    },
    inkomensDataPartner: {
      persoon: motiveringDl.klantNaamPartner || "Partner",
      eersteUitkering: partnerInkomstenPeriodeEerste?.wwUitkeringBedrag || null,
      tweedeUitkering: partnerInkomstenPeriodeEerste?.wwUitkeringBedrag || null,
      restinkomen: partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0,
      aantalmaandenWwUitkering: partnerInkomstenPeriodeEerste?.aantalMaandenWwUitkering || 0,
      totaalEerste: resultaat.totaalBrutoInkomenBedrag,
      totaalTweede: resultaat.totaalBrutoInkomenBedrag,
      totaalNa: resultaat.totaalBrutoInkomenBedrag,
      tekortEerste: normGegevens?.tekortGewenstBrutoInkomenBedrag,
      tekortTweede: 0, //todo,
      tekortNa: normGegevens?.tekortGewenstBrutoInkomenBedrag,
      totaalInkomen: null,
      totaalInkomenTweede:
        (partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0) +
        (partnerInkomstenPeriodeEerste?.wwUitkeringBedrag || 0) +
        (aanvragerInkomstenPeriodeEerst?.restInkomenBedrag || 0),
      totaalInkomenDerde:
        (partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0) +
        (aanvragerInkomstenPeriodeEerst?.restInkomenBedrag || 0)
    },
    jaarlijkseAnalyse: {
      gemiddeldeInkomen: resultaat.totaalBrutoInkomenGemiddeldBedrag,
      gewenstInkomen: normGegevens?.gewenstBrutoInkomenBedrag,
      gewenstInkomenPercentage:
        ((normGegevens.gewenstBrutoInkomenBedrag || 1) / (resultaat.totaalHuidigBrutoJaarinkomenBedrag || 1)) * 100,
      maandelijksTekort: normGegevens.gewenstBrutoInkomenBedrag
        ? Math.max(resultaat.totaalBrutoInkomenGemiddeldBedrag - normGegevens.gewenstBrutoInkomenBedrag, 0) / 12
        : 0
    },
    financieleGegevens: {
      toegestaneLast: normGegevens?.toegestaneLastBedrag,
      werkelijkeBrutoLast: normGegevens?.werkelijkeLastBedrag,
      tekortWerkelijkeBrutolast: (normGegevens?.toegestaneLastBedrag || 0) - (normGegevens?.werkelijkeLastBedrag || 0),
      maximaleHypotheek: normGegevens?.maximaleHypotheekBedrag,
      hypotheekSaldo: normGegevens?.hypotheeksaldoBedrag,
      overschrijding: Math.min(
        (normGegevens?.maximaleHypotheekBedrag || 0) - (normGegevens?.hypotheeksaldoBedrag || 0),
        0
      ),
      benodigdInkomenHypotheek: normGegevens.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0,
      tekortOpInkomen: normGegevens.tekortBenodigInkomenBedrag ? normGegevens.tekortBenodigInkomenBedrag / 12 : 0
    },
    voetnoten: motiveringDl.toelichtingRegels || [],
    benodigdKapitaal: motiveringDl.benodigdKapitaal?.bedrag || 0,
    benodigdKapitaalOmschrijving: motiveringDl.benodigdKapitaal?.omschrijving || ""
  };
}

export function mapMotiveringAOToInkomensanalyseProps(
  motiveringDl: MotiveringCompleetOutput
): ArbeidsongeschiktheidProps {
  const resultaat = motiveringDl.inkomensanalyseResultaat;
  const aanvragerInkomstenPeriodeEerst = resultaat?.aanvragerResultaten?.[0];
  const aanvragerInkomstenPeriodeTweede = resultaat?.aanvragerResultaten?.[1];
  const aanvragerInkomstenPeriodeDerde = resultaat?.aanvragerResultaten?.[2];
  const partnerInkomstenPeriodeEerste = resultaat?.partnerResultaten?.[0];
  const partnerInkomstenPeriodeTweede = resultaat?.partnerResultaten?.[1];
  const partnerInkomstenPeriodeDerde = resultaat?.partnerResultaten?.[2];
  const scenarioGegevens = motiveringDl.inkomensanalyseResultaat?.scenarioGegevens;
  const normGegevens = motiveringDl.normGegevens;

  if (!resultaat || !aanvragerInkomstenPeriodeEerst || !normGegevens) {
    return {} as ArbeidsongeschiktheidProps;
  }

  return {
    nbiWeergeven: false,
    inkomensanalyseResultaten: motiveringDl.motiveringInkomensanalyse?.resultaten || [],
    titel: "Inkomensanalyse bij arbeidsongeschiktheid",
    totaalHuidigBrutoJaarinkomenBedrag: resultaat.totaalHuidigBrutoJaarinkomenBedrag,
    conclusieEerste: aanvragerInkomstenPeriodeEerst.conclusie || "",
    conclusieTweede: aanvragerInkomstenPeriodeTweede?.conclusie || "",
    conclusieDerde: aanvragerInkomstenPeriodeDerde?.conclusie || "",
    nbi: motiveringDl.nettoBesteedbaarInkomen || [],
    inkomensData: {
      persoon: motiveringDl.klantNaamAanvrager || "Aanvrager",
      inkomenAanvragerEerstePeriode: aanvragerInkomstenPeriodeEerst.restInkomenBedrag,
      inkomenAanvragerTweedePeriode: aanvragerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
      inkomenAanvragerDerdePeriode: aanvragerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
      inkomenPartnerEerstePeriode: resultaat.partnerResultaten?.[0]?.restInkomenBedrag || null,
      inkomenPartnerTweedePeriode: resultaat.partnerResultaten?.[1]?.restInkomenBedrag || null,
      inkomenPartnerDerdePeriode: resultaat.partnerResultaten?.[2]?.restInkomenBedrag || null,

      wgaUitkeringAanvragerEerstePeriode: aanvragerInkomstenPeriodeEerst.wgaUitkeringBedrag,
      wgaUitkeringAanvragerTweedePeriode: aanvragerInkomstenPeriodeTweede?.wgaUitkeringBedrag || 0,
      wgaUitkeringAanvragerDerdePeriode: aanvragerInkomstenPeriodeDerde?.wgaUitkeringBedrag || 0,
      totaalInkomenEerstePeriode: optellen([
        aanvragerInkomstenPeriodeEerst.wgaUitkeringBedrag,
        resultaat.aanvragerResultaten?.[0]?.restInkomenBedrag || 0,
        resultaat.partnerResultaten?.[0]?.restInkomenBedrag || 0,
        resultaat.aanvragerResultaten?.[0]?.ivaUitkeringBedrag || 0
      ]),
      totaalInkomenTweedePeriode: optellen([
        aanvragerInkomstenPeriodeTweede?.wgaUitkeringBedrag || 0,
        resultaat.aanvragerResultaten?.[1]?.restInkomenBedrag || 0,
        resultaat.partnerResultaten?.[1]?.restInkomenBedrag || 0,
        resultaat.aanvragerResultaten?.[1]?.ivaUitkeringBedrag || 0
      ]),
      totaalInkomenDerdePeriode: optellen([
        aanvragerInkomstenPeriodeDerde?.wgaUitkeringBedrag || 0,
        resultaat.aanvragerResultaten?.[2]?.restInkomenBedrag || 0,
        resultaat.partnerResultaten?.[2]?.restInkomenBedrag || 0,
        resultaat.aanvragerResultaten?.[2]?.ivaUitkeringBedrag || 0
      ]),
      inkomensverliesEerste:
        resultaat.totaalHuidigBrutoJaarinkomenBedrag / 12 -
        (optellen([
          aanvragerInkomstenPeriodeEerst.wgaUitkeringBedrag,
          resultaat.aanvragerResultaten?.[0]?.restInkomenBedrag || 0,
          resultaat.partnerResultaten?.[0]?.restInkomenBedrag || 0,
          resultaat.aanvragerResultaten?.[0]?.ivaUitkeringBedrag || 0
        ]) || 0) /
          12,
      inkomensverliesTweede:
        resultaat.totaalHuidigBrutoJaarinkomenBedrag / 12 -
        (optellen([
          aanvragerInkomstenPeriodeTweede?.wgaUitkeringBedrag || 0,
          aanvragerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
          resultaat.partnerResultaten?.[1]?.restInkomenBedrag || 0,
          aanvragerInkomstenPeriodeTweede?.ivaUitkeringBedrag || 0
        ]) || 0) /
          12,
      inkomensverliesDerde:
        resultaat.totaalHuidigBrutoJaarinkomenBedrag / 12 -
        (optellen([
          aanvragerInkomstenPeriodeDerde?.wgaUitkeringBedrag || 0,
          resultaat.aanvragerResultaten?.[2]?.restInkomenBedrag || 0,
          resultaat.partnerResultaten?.[2]?.restInkomenBedrag || 0,
          resultaat.aanvragerResultaten?.[2]?.ivaUitkeringBedrag || 0
        ]) || 0) /
          12,
      percentageAoEerste: scenarioGegevens?.percentageArbeidsongeschiktheid || 0,
      percentageAoTweede: scenarioGegevens?.percentageArbeidsongeschiktheidExtra1 || 0,
      percentageAoDerde: scenarioGegevens?.percentageArbeidsongeschiktheidExtra2 || 0,
      ivaUitkeringAanvragerEerstePeriode: aanvragerInkomstenPeriodeEerst?.ivaUitkeringBedrag,
      ivaUitkeringAanvragerTweedePeriode: aanvragerInkomstenPeriodeTweede?.ivaUitkeringBedrag || 0,
      ivaUitkeringAanvragerDerdePeriode: aanvragerInkomstenPeriodeDerde?.ivaUitkeringBedrag || 0,
      woonlastenEerstePeriode: aanvragerInkomstenPeriodeEerst.woonlastenverzekeringBedrag || 0,
      woonlastenTweedePeriode: aanvragerInkomstenPeriodeTweede?.woonlastenverzekeringBedrag || 0,
      woonlastenDerdePeriode: aanvragerInkomstenPeriodeDerde?.woonlastenverzekeringBedrag || 0,
      rvcEerste: scenarioGegevens?.verdiencapaciteitPercentage || 0,
      rvcTweede: scenarioGegevens?.verdiencapaciteitPercentageExtra1 || 0,
      rvcDerde: scenarioGegevens?.verdiencapaciteitPercentageExtra2 || 0
    },
    jaarlijkseAnalyse: {
      gewenstInkomen: normGegevens.gewenstBrutoInkomenBedrag,
      maandelijksTekortEerste: Math.min(
        (optellen([
          aanvragerInkomstenPeriodeEerst.wgaUitkeringBedrag,
          resultaat.aanvragerResultaten?.[0]?.restInkomenBedrag || 0,
          resultaat.partnerResultaten?.[0]?.restInkomenBedrag || 0,
          resultaat.aanvragerResultaten?.[0]?.ivaUitkeringBedrag || 0
        ]) || 0) /
          12 -
          (normGegevens.gewenstBrutoInkomenBedrag || 0) / 12,
        0 // min0
      ),
      maandelijksTekortTweede: Math.min(
        (optellen([
          aanvragerInkomstenPeriodeTweede?.wgaUitkeringBedrag || 0,
          resultaat.aanvragerResultaten?.[1]?.restInkomenBedrag || 0,
          resultaat.partnerResultaten?.[1]?.restInkomenBedrag || 0,
          resultaat.aanvragerResultaten?.[1]?.ivaUitkeringBedrag || 0
        ]) || 0) /
          12 -
          (normGegevens.gewenstBrutoInkomenBedrag || 0) / 12,
        0 // min0
      ),
      maandelijksTekortDerde: Math.min(
        optellen([
          aanvragerInkomstenPeriodeDerde?.wgaUitkeringBedrag || 0,
          resultaat.aanvragerResultaten?.[2]?.restInkomenBedrag || 0,
          resultaat.partnerResultaten?.[2]?.restInkomenBedrag || 0,
          resultaat.aanvragerResultaten?.[2]?.ivaUitkeringBedrag || 0
        ]) || 0 / 12 - (normGegevens.gewenstBrutoInkomenBedrag || 0) / 12,
        0
      ) // min0
    },
    financieleGegevens: {
      toegestaneLast: normGegevens?.toegestaneLastBedrag || 0,
      werkelijkeBrutoLast: normGegevens?.werkelijkeLastBedrag || 0,
      tekortOpInkomen: Math.abs(
        normGegevens.tekortBenodigInkomenBedrag ? normGegevens.tekortBenodigInkomenBedrag / 12 : 0
      ),
      tekortOpInkomenTweede: Math.min(
        (optellen([
          resultaat.aanvragerResultaten?.[1]?.wgaUitkeringBedrag || 0,
          resultaat.aanvragerResultaten?.[1]?.restInkomenBedrag || 0,
          resultaat.partnerResultaten?.[1]?.restInkomenBedrag || 0,
          resultaat.aanvragerResultaten?.[1]?.ivaUitkeringBedrag || 0
        ]) || 0) /
          12 -
          (normGegevens.benodigdInkomenBedrag || 0) / 12,
        0 //min0
      ),
      tekortOpInkomenDerde: Math.min(
        (optellen([
          resultaat.aanvragerResultaten?.[2]?.wgaUitkeringBedrag || 0,
          resultaat.aanvragerResultaten?.[2]?.restInkomenBedrag || 0,
          resultaat.partnerResultaten?.[2]?.restInkomenBedrag || 0,
          resultaat.aanvragerResultaten?.[2]?.ivaUitkeringBedrag || 0
        ]) || 0) /
          12 -
          (normGegevens.benodigdInkomenBedrag || 0) / 12,
        0
      ),
      tekortWerkelijk: normGegevens?.tekortBenodigInkomenBedrag || 0,
      tekortWerkelijkeBrutolast: Math.min(
        (normGegevens?.toegestaneLastBedrag || 0) - (normGegevens?.werkelijkeLastBedrag || 0),
        0
      ),
      maximaleHypotheekEerste: normGegevens?.maximaleHypotheekBedrag,
      maximaleHypotheekTweede: normGegevens?.maximaleHypotheekBedrag,
      maximaleHypotheekDerde: normGegevens?.maximaleHypotheekBedrag,
      hypotheekSaldo: normGegevens?.hypotheeksaldoBedrag,
      overschrijding: Math.min(
        (normGegevens?.maximaleHypotheekBedrag || 0) - (normGegevens?.hypotheeksaldoBedrag || 0),
        0
      ),
      benodigdInkomenHypotheek: normGegevens.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0,
      maandelijksTekortHypotheekEerste:
        Math.min(
          (optellen([
            aanvragerInkomstenPeriodeEerst.wgaUitkeringBedrag,
            resultaat.aanvragerResultaten?.[0]?.restInkomenBedrag || 0,
            resultaat.partnerResultaten?.[0]?.restInkomenBedrag || 0,
            resultaat.aanvragerResultaten?.[0]?.ivaUitkeringBedrag || 0
          ]) || 0) /
            12 -
            (normGegevens.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0)
        ) *
        (1 - (resultaat.aanvragerResultaten?.[0]?.bruteringPercentage || 0) / 100), //totaal-inkomen - minimaalBenodigHypotheek,
      maandelijksTekortHypotheekTweede:
        Math.min(
          (optellen([
            aanvragerInkomstenPeriodeTweede?.wgaUitkeringBedrag || 0,
            resultaat.aanvragerResultaten?.[1]?.restInkomenBedrag || 0,
            resultaat.partnerResultaten?.[1]?.restInkomenBedrag || 0,
            resultaat.aanvragerResultaten?.[1]?.ivaUitkeringBedrag || 0
          ]) || 0) /
            12 -
            (normGegevens.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0)
        ) *
        (1 - (resultaat.aanvragerResultaten?.[1]?.bruteringPercentage || 0) / 100),
      maandelijksTekortHypotheekDerde:
        Math.min(
          (optellen([
            aanvragerInkomstenPeriodeDerde?.wgaUitkeringBedrag || 0,
            resultaat.aanvragerResultaten?.[2]?.restInkomenBedrag || 0,
            resultaat.partnerResultaten?.[2]?.restInkomenBedrag || 0,
            resultaat.aanvragerResultaten?.[2]?.ivaUitkeringBedrag || 0
          ]) || 0) /
            12 -
            (normGegevens.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0)
        ) *
        (1 - (resultaat.aanvragerResultaten?.[2]?.bruteringPercentage || 0) / 100)
    },
    voetnoten: motiveringDl.toelichtingRegels || [],
    benodigdKapitaal: motiveringDl.benodigdKapitaal?.bedrag || null,
    benodigdKapitaalOmschrijving: motiveringDl.benodigdKapitaal?.omschrijving || ""
  };
}

export function mapMotiveringOverlijdenToInkomensanalyseProps(motiveringDl: MotiveringCompleetOutput): OverlijdenProps {
  const resultaat = motiveringDl.inkomensanalyseResultaat;
  const nabestaande = resultaat?.partnerResultaten?.[0];
  const normGegevens = motiveringDl.normGegevens;

  if (!resultaat || !nabestaande || !normGegevens) {
    return {} as OverlijdenProps;
  }

  var totaalResInkomen = (nabestaande.anwUitkeringBedrag || 0) + (nabestaande.restInkomenBedrag || 0);

  return {
    nbiWeergeven: false,
    inkomensanalyseResultaten: motiveringDl.motiveringInkomensanalyse?.resultaten || [],
    titel: "Inkomensanalyse bij overlijden",
    totaalHuidigBrutoJaarinkomenBedrag: resultaat.totaalHuidigBrutoJaarinkomenBedrag,
    conclusie: nabestaande.conclusie || "",
    nbi: motiveringDl.nettoBesteedbaarInkomen || [],
    controleInkomenWeergeven: false, // wordt geoverride door de props (komt uit dashboard-instellingen)
    inkomensData: {
      anwUitkeringBedrag: nabestaande.anwUitkeringBedrag ? nabestaande.anwUitkeringBedrag / 12 : 0,
      anwHiaatVerzekeringBedrag: nabestaande.anwHiaatVerzekeringBedrag ? nabestaande.anwHiaatVerzekeringBedrag / 12 : 0,
      anwHiaatVerzekeringBruteringBedrag: nabestaande.anwHiaatVerzekeringBruteringBedrag
        ? nabestaande.anwHiaatVerzekeringBruteringBedrag / 12
        : 0,
      nabestaandenpensioen: nabestaande.nabestaandenpensioenBedrag,
      wezenpensioen: nabestaande.wezenPensioenuitkeringBedrag,
      inkomenPartner: nabestaande.restInkomenBedrag ? nabestaande.restInkomenBedrag / 12 : 0,
      totaalInkomen: totaalResInkomen > 0 ? totaalResInkomen / 12 : 0,
      inkomensverlies: (resultaat.totaalHuidigBrutoJaarinkomenBedrag || 0) / 12 - totaalResInkomen / 12
    },
    jaarlijkseAnalyse: {
      gewenstInkomen: normGegevens?.gewenstBrutoInkomenBedrag,
      gewenstInkomenPercentage:
        ((normGegevens.gewenstBrutoInkomenBedrag || 1) / (resultaat.totaalHuidigBrutoJaarinkomenBedrag || 1)) * 100,
      maandelijksTekort: normGegevens.gewenstBrutoInkomenBedrag
        ? resultaat.totaalBrutoInkomenGemiddeldBedrag - normGegevens.gewenstBrutoInkomenBedrag
        : 0
    },
    financieleGegevens: {
      benodigdInkomenHypotheek: normGegevens.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0,
      tekortOpInkomen: normGegevens.tekortBenodigInkomenBedrag ? normGegevens.tekortBenodigInkomenBedrag / 12 : 0,
      maximaleHypotheek: normGegevens?.maximaleHypotheekBedrag || null,
      hypotheekSaldo: normGegevens?.hypotheeksaldoBedrag || null,
      overschrijding: Math.min(
        (normGegevens?.maximaleHypotheekBedrag || 0) - (normGegevens?.hypotheeksaldoBedrag || 0),
        0
      ),
      toegestaneLast: normGegevens?.toegestaneLastBedrag || null,
      werkelijkeBrutoLast: normGegevens?.werkelijkeLastBedrag || null,
      tekortWerkelijkeBrutolast: (normGegevens?.toegestaneLastBedrag || 0) - (normGegevens?.werkelijkeLastBedrag || 0)
    },
    voetnoten: motiveringDl.toelichtingRegels || [],
    benodigdKapitaal: motiveringDl.benodigdKapitaal?.bedrag || null,
    benodigdKapitaalOmschrijving: motiveringDl.benodigdKapitaal?.omschrijving || ""
  };
}

export const mapMotiveringPensioenToInkomensanalyseProps = (
  motiveringDl: MotiveringCompleetOutput
): PensioenDataProps => {
  const aanvragerInkomstenPeriode = motiveringDl?.inkomensanalyseResultaat?.aanvragerResultaten?.[0];
  const partnerInkomstenPeriode = motiveringDl?.inkomensanalyseResultaat?.partnerResultaten?.[0];
  const normGegevens = motiveringDl?.normGegevens;
  const resultaat = motiveringDl.inkomensanalyseResultaat;

  if (!resultaat) throw Error("nooo");

  return {
    nbiWeergeven: false,
    inkomensanalyseResultaten: motiveringDl.motiveringInkomensanalyse?.resultaten || [],
    titel: "",
    totaalHuidigBrutoJaarinkomenBedrag: resultaat.totaalHuidigBrutoJaarinkomenBedrag,
    conclusie: aanvragerInkomstenPeriode?.conclusie || "",
    nbi: motiveringDl.nettoBesteedbaarInkomen || [],
    inkomensData: {
      restinkomenAanvrager:
        (aanvragerInkomstenPeriode?.aowUitkeringBedrag || 0) +
        (aanvragerInkomstenPeriode?.werkgeverspensioenBedrag || 0),
      restinkomenPartner: partnerInkomstenPeriode?.restInkomenBedrag || null,
      totaalInkomen:
        (aanvragerInkomstenPeriode?.restInkomenBedrag || 0) +
        (partnerInkomstenPeriode?.restInkomenBedrag || 0) +
        (aanvragerInkomstenPeriode?.aowUitkeringBedrag || 0) +
        (partnerInkomstenPeriode?.aowUitkeringBedrag || 0) +
        (aanvragerInkomstenPeriode?.werkgeverspensioenBedrag || 0) +
        (partnerInkomstenPeriode?.werkgeverspensioenBedrag || 0),
      inkomensverlies: resultaat?.totaalHuidigBrutoJaarinkomenBedrag / 12 - resultaat?.totaalBrutoInkomenBedrag / 12,
      werkgeversPensioenAanvrager: aanvragerInkomstenPeriode?.werkgeverspensioenBedrag || null,
      werkgeversPensioenPartner: partnerInkomstenPeriode?.werkgeverspensioenBedrag || null,
      aowInkomenAanvrager: aanvragerInkomstenPeriode?.aowUitkeringBedrag || null,
      aowInkomenPartner: partnerInkomstenPeriode?.aowUitkeringBedrag || null
    },
    financieleGegevens: {
      huidigInkomen70Procent: (resultaat?.totaalHuidigBrutoJaarinkomenBedrag / 12) * 0.7,
      toegestaneLast: normGegevens?.toegestaneLastBedrag || null,
      werkelijkeBrutoLast: normGegevens?.werkelijkeLastBedrag || null,
      tekortWerkelijkeBrutolast: (normGegevens?.toegestaneLastBedrag || 0) - (normGegevens?.werkelijkeLastBedrag || 0),
      tekortWerkelijk: normGegevens?.tekortBenodigInkomenBedrag || null,
      maximaleHypotheek: normGegevens?.maximaleHypotheekBedrag || null,
      hypotheekSaldo: normGegevens?.hypotheeksaldoBedrag || null,
      overschrijding: Math.min(
        (normGegevens?.maximaleHypotheekBedrag || 0) - (normGegevens?.hypotheeksaldoBedrag || 0),
        0
      ),
      benodigdInkomenHypotheek: normGegevens?.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0,
      tekortOpInkomen: normGegevens?.tekortBenodigInkomenBedrag ? normGegevens.tekortBenodigInkomenBedrag / 12 : 0
    },
    jaarlijkseAnalyse: {
      gewenstInkomen: normGegevens?.gewenstBrutoInkomenBedrag || 0,
      gewenstInkomenPercentage:
        ((normGegevens?.gewenstBrutoInkomenBedrag || 1) / (resultaat.totaalHuidigBrutoJaarinkomenBedrag || 1)) * 100,
      maandelijksTekort:
        ((aanvragerInkomstenPeriode?.restInkomenBedrag || 0) +
          (partnerInkomstenPeriode?.restInkomenBedrag || 0) +
          (aanvragerInkomstenPeriode?.aowUitkeringBedrag || 0) +
          (partnerInkomstenPeriode?.aowUitkeringBedrag || 0) +
          (aanvragerInkomstenPeriode?.werkgeverspensioenBedrag || 0) +
          (partnerInkomstenPeriode?.werkgeverspensioenBedrag || 0) -
          (normGegevens?.gewenstBrutoInkomenBedrag || 0)) /
        12
    },
    voetnoten: motiveringDl.toelichtingRegels || [],
    benodigdKapitaal: motiveringDl.benodigdKapitaal?.bedrag || 0,
    benodigdKapitaalOmschrijving: motiveringDl.benodigdKapitaal?.omschrijving || ""
  };
};
